export function transformAssetGroup(assetgroup, moduleName) {
  return {
    id: assetgroup.id,
    name: assetgroup.name,
    description: assetgroup.description,
    owner: assetgroup.owner,
    canDelete: assetgroup.deleteableOob,
    canEdit: assetgroup.updatebleOob,
    assetGroupEmails: assetgroup.assetGroupEmails,
    moduleName: assetgroup.model,
    archived: assetgroup.removed,
  }
}

export function transformAssetGroupForServer(assetgroup, moduleName) {
  return {
    name: assetgroup.name,
    description: assetgroup.description,
    owner: assetgroup.owner,
    assetGroupEmails: assetgroup.assetGroupEmails,
    model: moduleName,
  }
}
