export function transformComputerForList(computer) {
  return {
    id: computer.id,
    name: computer.name,
    hostName: computer.hostName,
    ipAddress: computer.ipAddress,
    pollerId: computer.pollerId,
    osName: computer.osName,
    agentVersion: computer.agentVersion,
    domainName: computer.domainName,
    lastRefreshCallTime: computer.lastRefreshCallTime,
    active: computer.active,
    agentActiveStatus: computer.agentActiveStatus,
    refreshCycle: computer.refreshCycle,
    discoveryCycle: computer.discoveryCycle,
    meterCycle: computer.meterCycle,
    meterDataCycle: computer.meterDataCycle,
    redhatRepoSyncCycle: computer.redhatRepoSyncCycle,
    usageCount: computer.usageCount,
    usageDuration: computer.usageDuration,
    lastUsedTime: computer.lastUsedTime,
    servicePack: computer.servicePack,
    remoteOfficeId: computer.remoteOfficeId,
    language: computer.language,
    loggedOnUser: computer.loggedOnUser,
    installedPatch: computer.installedPatches,
    missingPatch: computer.missingPatches,
    tags: computer.tags,
    architecture: computer.architecture,
    healthType: computer.healthType,
    osVersion: computer.osVersion,
    lastPatchScanTime: computer.lastPatchScanTime,
    rebootRequired: computer.rebootRequired,
    patchType: computer.patchType,
    credentialProfileId: computer.credentialProfileId,
    credentialProfileName: computer.credentialProfileName,
  }
}

export function transformComputerForDetail(computer) {
  const listProps = transformComputerForList(computer)
  return {
    ...listProps,
    platform: computer.platform,
    osIdInSwCatalog: computer.osIdInSwCatalog,
    lastMonitorCallTime: computer.lastMonitorCallTime,
    locationId: computer.locationId,
    uuid: computer.uuid,
    patchEnabled: computer.patchEnabled,
    sysInfo: computer.sysInfo,
    tags: computer.tags,
    osAppIds: computer.osAppIds,
    lastRebootTime: computer.lastRebootTime,
    relevantUbuntuVersion: computer.relevantUbuntuVersion,
  }
}

export function transformComputerForServer(computer) {
  const transformedProps = []
  const obj = {}
  Object.keys(computer).forEach((paramName) => {
    if (!transformedProps.includes(paramName)) {
      obj[paramName] = computer[paramName]
    }
  })
  if ('description' in computer) {
    obj.description = computer.description || ''
  }
  return obj
}
