import SortBy from 'lodash/sortBy'
import Omit from 'lodash/omit'
import Invert from 'lodash/invert'
import { generateId } from '@utils/id'
import CONSTANTS from '@constants'
import { getAssetCiTypes, isLoggedIn } from '@utils/auth'
import {
  flattenRecursive,
  transformRecursive,
  transformRecursiveForBuildTree,
} from '@data/recursive'
import {
  transformDependentFieldOptions,
  transformDependentFieldOptionsForServer,
} from '@data/dependent-field-options'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '../utils/get-module-translator'
import { buildGenericImageUrlFromRefFileName } from '@data/attachment'
import { customFieldAttributesTypePrefix } from '@src/modules/asset/helpers/asset-custom-fields'

export const useOnPortalEnabledModules = [
  CONSTANTS.REQUEST,
  CONSTANTS.SERVICE_CATALOG,
]

export const fieldTypeMap = {
  dropdown: 'DropDownFieldRest',
  multiSelectDropdown: 'MultiSelectDropDownFieldRest',
  radio: 'DropDownFieldRest',
  checkbox: 'CheckBoxFieldRest',
  date: 'DateFieldRest',
  label: 'DisplayFieldRest',
  spacer: 'DisplayFieldRest',
  number: 'NumberFieldRest',
  datetime: 'DateFieldRest',
  textarea: 'TextAreaFieldRest',
  richtextarea: 'RichTextAreaFieldRest',
  text: 'TextFieldRest',
  section: 'DisplayFieldRest',
  upload: 'AttachmentFieldRest',
  SystemFieldRest: 'SystemFieldRest',
  dependent: 'DependentFieldRest',
  api: 'APIFieldRest',
  rating: 'RatingFieldRest',
  boolean: 'BooleanFieldRest',
  slider: 'SliderFieldRest',
  dynamic: 'DynamicFieldRest',
}

export const getOperatorMaps = () => {
  const __t = getRootTranslator()
  return {
    equal: [{ name: __t('equal'), value: 'equal', key: 'equal' }],
    contains_only: [
      { name: __t('contains'), value: 'contains', key: 'contains' },
    ],
    contains: [
      { name: __t('contains'), value: 'contains', key: 'contains' },
      { name: __t('is_blank'), value: 'is_blank', key: 'is_blank' },
      { name: __t('is_not_blank'), value: 'is_not_blank', key: 'is_not_blank' },
    ],
    text: [
      {
        name: __t('equal_case_insensitive'),
        value: 'equal_case_insensitive',
        key: 'equal_case_insensitive',
      },
      {
        name: __t('not_equal_case_insensitive'),
        value: 'not_equal_case_insensitive',
        key: 'not_equal_case_insensitive',
      },
      { name: __t('start_with'), value: 'start_with', key: 'start_with' },
      { name: __t('end_with'), value: 'end_with', key: 'end_with' },
      { name: __t('contains'), value: 'contains', key: 'contains' },
      { name: __t('is_blank'), value: 'is_blank', key: 'is_blank' },
      { name: __t('is_not_blank'), value: 'is_not_blank', key: 'is_not_blank' },
    ],
    text_without_blank_notblank: [
      {
        name: __t('equal_case_insensitive'),
        value: 'equal_case_insensitive',
        key: 'equal_case_insensitive',
      },
      {
        name: __t('not_equal_case_insensitive'),
        value: 'not_equal_case_insensitive',
        key: 'not_equal_case_insensitive',
      },
      { name: __t('start_with'), value: 'start_with', key: 'start_with' },
      { name: __t('end_with'), value: 'end_with', key: 'end_with' },
      { name: __t('contains'), value: 'contains', key: 'contains' },
    ],
    text_without_not_equal: [
      {
        name: __t('equal_case_insensitive'),
        value: 'equal_case_insensitive',
        key: 'equal_case_insensitive',
      },
      { name: __t('start_with'), value: 'start_with', key: 'start_with' },
      { name: __t('end_with'), value: 'end_with', key: 'end_with' },
      { name: __t('contains'), value: 'contains', key: 'contains' },
      { name: __t('is_blank'), value: 'is_blank', key: 'is_blank' },
      { name: __t('is_not_blank'), value: 'is_not_blank', key: 'is_not_blank' },
    ],
    text_without_unary_operator: [
      {
        name: __t('equal_case_insensitive'),
        value: 'equal_case_insensitive',
        key: 'equal_case_insensitive',
      },
      {
        name: __t('not_equal_case_insensitive'),
        value: 'not_equal_case_insensitive',
        key: 'not_equal_case_insensitive',
      },
      { name: __t('start_with'), value: 'start_with', key: 'start_with' },
      { name: __t('end_with'), value: 'end_with', key: 'end_with' },
      { name: __t('contains'), value: 'contains', key: 'contains' },
    ],
    listWithEqual: [
      { name: __t('in'), value: 'in', key: 'in' },
      { name: __t('not_in'), value: 'not_in', key: 'not_in' },
      { name: __t('equal'), value: 'equal', key: 'equal' },
    ],
    list: [
      { name: __t('in'), value: 'in', key: 'in' },
      { name: __t('not_in'), value: 'not_in', key: 'not_in' },
    ],
    listCaseInsensitive: [
      {
        name: __t('in'),
        value: 'in_case_insensitive',
        key: 'in_case_insensitive',
      },
      {
        name: __t('not_in'),
        value: 'not_in_case_insensitive',
        key: 'not_in_case_insensitive',
      },
    ],
    in: [{ name: __t('in'), value: 'in', key: 'in' }],
    tag: [
      {
        name: __t('any_member_or_all_members_exist'),
        value: 'any_member_or_all_members_exist',
        key: 'any_member_or_all_members_exist',
      },
      {
        name: __t('all_members_exist'),
        value: 'all_members_exist',
        key: 'all_members_exist',
      },
    ],
    number: [
      { name: __t('equal'), value: 'equal', key: 'equal' },
      { name: __t('not_equal'), value: 'not_equal', key: 'not_equal' },
      { name: __t('greater_than'), value: 'greater_than', key: 'greater_than' },
      { name: __t('less_than'), value: 'less_than', key: 'less_than' },
      { name: __t('between'), value: 'between', key: 'between' },
    ],
    date: [
      { name: __t('equal'), value: 'equal', key: 'equal' },
      { name: __t('greater_than'), value: 'greater_than', key: 'greater_than' },
      { name: __t('less_than'), value: 'less_than', key: 'less_than' },
      { name: __t('between'), value: 'between', key: 'between' },
      { name: __t('before'), value: 'before', key: 'before' },
      { name: __t('after'), value: 'after', key: 'after' },
      { name: __t('exactly_next'), value: 'exactly_next', key: 'exactly_next' },
      { name: __t('exactly_last'), value: 'exactly_last', key: 'exactly_last' },
      { name: __t('within_next'), value: 'within_next', key: 'within_next' },
      { name: __t('within_last'), value: 'within_last', key: 'within_last' },
      { name: __t('between_next'), value: 'between_next', key: 'between_next' },
      { name: __t('between_last'), value: 'between_last', key: 'between_last' },
    ],
    date_without_greater_than: [
      { name: __t('equal'), value: 'equal', key: 'equal' },
      { name: __t('less_than'), value: 'less_than', key: 'less_than' },
      { name: __t('between'), value: 'between', key: 'between' },
      { name: __t('before'), value: 'before', key: 'before' },
      { name: __t('after'), value: 'after', key: 'after' },
    ],
    boolean: [
      { name: __t('equal'), value: 'equal', key: 'equal' },
      { name: __t('not_equal'), value: 'not_equal', key: 'not_equal' },
    ],
    tag_with_no_member: [
      {
        name: __t('any_member_or_all_members_exist'),
        value: 'any_member_or_all_members_exist',
        key: 'any_member_or_all_members_exist',
      },
      {
        name: __t('all_members_exist'),
        value: 'all_members_exist',
        key: 'all_members_exist',
      },
      {
        name: __t('no_members_exist'),
        value: 'no_members_exist',
        key: 'no_members_exist',
      },
    ],
    members_exist_with_not_any_members_exist: [
      {
        name: __t('any_member_or_all_members_exist'),
        value: 'any_member_or_all_members_exist',
        key: 'any_member_or_all_members_exist',
      },
      {
        name: __t('all_members_exist'),
        value: 'all_members_exist',
        key: 'all_members_exist',
      },
      {
        name: __t('not_any_members_exist'),
        value: 'not_any_members_exist',
        key: 'not_any_members_exist',
      },
    ],
    blank_not_blank: [
      { name: __t('is_blank'), value: 'is_blank', key: 'is_blank' },
      { name: __t('is_not_blank'), value: 'is_not_blank', key: 'is_not_blank' },
    ],
    null_not_null: [
      { name: __t('is_null'), value: 'is_null', key: 'is_null' },
      { name: __t('is_not_null'), value: 'is_not_null', key: 'is_not_null' },
    ],
    empty_not_empty: [
      { name: __t('is_empty'), value: 'is_empty', key: 'is_empty' },
      { name: __t('is_not_empty'), value: 'is_not_empty', key: 'is_not_empty' },
    ],
    same_as_not_same_as: [
      { name: __t('same_as'), value: 'same_as', key: 'same_as' },
      { name: __t('not_same_as'), value: 'not_same_as', key: 'not_same_as' },
    ],
    hertz_value_operators: [
      { name: __t('greater_than'), value: 'greater_than', key: 'greater_than' },
      { name: __t('less_than'), value: 'less_than', key: 'less_than' },
      { name: __t('between'), value: 'between', key: 'between' },
    ],
  }
}

export function transformControl(control) {
  return {
    guid: generateId(),
    type: control.type,
    name: control.type,
    icon: 'question',
    isSystemControl: true,
  }
}

export function transformForm(form) {
  return {
    id: form.id,
    archived: form.archived,
    name: form.name,
    moduleName: form.refModel,
    fields: SortBy(
      transformFields(form.fieldList || [], form.refModel),
      'order'
    ),
    systemFields: (form.fieldList || [])
      .filter((f) => f.type === 'SystemFieldRest')
      .map((f) => f.systemType),
  }
}

export function transformFields(fields, moduleName) {
  const sameNameFieldsMap = {}
  if ([CONSTANTS.ASSET].indexOf(moduleName) >= 0) {
    let flattenFieldList = flattenFields(fields)
    let assetTypes = flattenRecursive(getAssetCiTypes('asset'))
    let ciTypes = flattenRecursive(getAssetCiTypes('cmdb'))

    flattenFieldList.forEach((field) => {
      const model = field.attributes.model
      const typePrefix = customFieldAttributesTypePrefix(model)
      const assetCiTypeId =
        field.attributes[`${typePrefix}TypeId`] &&
        field.attributes[`${typePrefix}TypeId`] !== 'all'
          ? +field.attributes[`${typePrefix}TypeId`]
          : undefined
      const types = model === 'cmdb' ? ciTypes : assetTypes
      const assetCiTypeName = assetCiTypeId
        ? (types.find((t) => t.id === assetCiTypeId) || {}).name
        : ''
      if (
        sameNameFieldsMap[field.name] &&
        sameNameFieldsMap[field.name].model === model
      ) {
        sameNameFieldsMap[field.name][field.id] = assetCiTypeName
        sameNameFieldsMap[field.name].total =
          sameNameFieldsMap[field.name].total + 1
      } else {
        sameNameFieldsMap[field.name] = {
          [field.id]: assetCiTypeName,
          model: model,
          total: 1,
        }
      }
    })
  }
  const transformedFields = []
  fields
    .filter((f) => !f.sectionId)
    .forEach((field) => {
      const transformedField = transformField(
        field,
        moduleName,
        sameNameFieldsMap
      )
      if (field.subType === 'section') {
        transformedField.fields = SortBy(
          fields
            .filter((f) => f.sectionId === transformedField.id)
            .map((i) => transformField(i, moduleName, sameNameFieldsMap)),
          'order'
        )
      }
      transformedFields.push(transformedField)
    })
  return transformedFields
}

export function flattenFields(fields) {
  return fields.reduce((result, field) => {
    const subFields = field.fields || []
    const currentField = Omit(field, 'fields')
    return result.concat(currentField, subFields)
  }, [])
}

export function getFieldIds(fields) {
  return flattenFields(fields).map((f) => f.paramName || f.id)
}

export function nestFields(fields) {
  return (fields || [])
    .filter((f) => !f.sectionId)
    .map((f) => ({
      ...f,
      fields: fields.filter((subField) => subField.sectionId === f.id),
    }))
}

// transform dependent field's tree property
export function transformTreePropertyRecursive(tree) {
  return transformRecursive(tree, transformDependentFieldOptions)
}

// transform dependent field's tree property for server
export function transformTreePropertyRecursiveForServer(tree) {
  return transformRecursiveForBuildTree(
    tree,
    transformDependentFieldOptionsForServer
  )
}

// api field converter for client
export function transformApiField(field) {
  return {
    apiFieldType: field.fieldType,
    requestType: field.requestType,
    url: field.url,
    secureApi: field.secureApi,
    userName: field.userName,
    password: field.password,
    pluginIdentifier: field.pluginIdentifier,
    pathvariable: field.pathvariable,
    requestParam: field.requestParam,
    inputMapping: (field.inputMapping || []).map((m) => ({
      ...m,
      guid: generateId(),
    })),
    outputMapping: (field.outputMapping || []).map((m) => ({
      ...m,
      guid: generateId(),
    })),
    requestJson: field.requestJson,
    message: field.message,
    apiActionCredentialType: field.apiActionCredentialType,
    credentialId: field.credentialId,
    headers: (field.headers || []).map((m) => ({
      ...m,
      guid: generateId(),
    })),
    proxyConfigId: field.proxyConfigId,
  }
}

// api field converter for server
export function transformApiFieldForServer(field) {
  return {
    fieldType: field.apiFieldType,
    requestType: field.requestType,
    url: field.url,
    secureApi: field.secureApi,
    userName: field.userName,
    password: field.password,
    pluginIdentifier: field.pluginIdentifier,
    pathvariable: field.pathvariable,
    requestParam: field.requestParam,
    inputMapping: field.inputMapping,
    outputMapping: field.outputMapping,
    requestJson: field.requestJson,
    message: field.message,
    apiActionCredentialType: field.apiActionCredentialType,
    credentialId: field.credentialId,
    headers: field.headers,
    proxyConfigId: field.proxyConfigId,
  }
}

export function transformRatingField(field) {
  return {
    ratingScales: (field.ratingScales || []).map((m, index) => ({
      ...m,
      guid: generateId(),
      scaleNumber: m.scaleNumber,
      emoji: m.emoji,
      description: m.description,
      emojiFileRefName: m.emojiFileRefName,
      emojiFileSrc: m.emojiFileRefName
        ? buildGenericImageUrlFromRefFileName(m.emojiFileRefName)
        : undefined,
    })),
    ratingType: field.rateOption || 'star',
  }
}

export function transformRatingFieldForServer(field) {
  return {
    ratingScales: (field.ratingScales || []).map((m, index) => ({
      scaleNumber: index + 1,
      emoji: m.emoji,
      description: m.description,
      emojiFileRefName: m.emojiFileRefName,
    })),
    rateOption: field.ratingType,
  }
}

export function transformFieldAttributes(attributes, type) {
  return {
    ...attributes,
    ...(attributes.allowOnlyToSelectDependentLeafNode
      ? {
          allowOnlyToSelectDependentLeafNode:
            attributes.allowOnlyToSelectDependentLeafNode !== 'false',
        }
      : {}),
    ...(attributes.displayHierarchyInReport
      ? {
          displayHierarchyInReport:
            attributes.displayHierarchyInReport !== 'false',
        }
      : {}),
    ...(attributes.allowDecimal
      ? { allowDecimal: attributes.allowDecimal !== 'false' }
      : {}),
    ...(attributes.allowTime
      ? { allowTime: attributes.allowTime !== 'false' }
      : {}),
    ...(attributes.systemManagedField
      ? { systemManagedField: attributes.systemManagedField !== 'false' }
      : {}),
    ...(attributes.inputWidth
      ? { inputWidth: parseInt(attributes.inputWidth, 10) }
      : {
          inputWidth:
            type === 'textarea' ||
            type === 'richtextarea' ||
            type === 'description'
              ? 100
              : 40,
        }),
    ...(attributes.displayOptionVertically
      ? {
          displayOptionVertically:
            attributes.displayOptionVertically !== 'false',
        }
      : {}),
  }
}

export function transformField(field, moduleName, sameNameFieldsMap = {}) {
  let type = Invert(fieldTypeMap)[field.type]
  if (!type) {
    throw new Error(`Field with type ${type} is not defined`)
  }
  if (field.type === 'DropDownFieldRest') {
    type = 'dropdown'
  }
  if (field.type === 'multiSelectDropdown') {
    type = 'multiSelectDropdown'
  }
  if (field.radioButtonField) {
    type = 'radio'
  }
  if (field.type === 'DisplayFieldRest') {
    type = field.subType
  }
  return {
    id: field.id,
    moduleName,
    type,
    isSystemField: field.type === 'SystemFieldRest',
    ...(field.type === 'SystemFieldRest' ? { paramName: field.paramName } : {}),
    inputType: field.type === 'SystemFieldRest' ? field.systemType : type,
    guid: generateId(),
    title: field.name,
    attributes: field.attributes
      ? transformFieldAttributes(field.attributes, type)
      : {
          widthClass: 'w-full',
          placeholder: field.name,
        },
    defaultValue: field.defaultValue,
    order: field.orderInField,
    question: field.question,
    maxRating: field.maxRating,
    fLabel: field.fLabel,
    tLabel: field.tLabel,
    minLength: field.minLength,
    maxLength: field.maxLength,
    minValue: field.minValue,
    maxValue: field.maxValue,
    required: field.required,
    markAsHidden: field.markAsHidden,
    scriptEvent: field.event,
    scriptId: field.scriptId,
    useOnPortal:
      field.systemType === 'template' && !isLoggedIn()
        ? false
        : field.requesterViewOnly,
    // access level for requester
    requesterGroups: field.groupIds || [],
    sectionId: field.sectionId,
    canDelete: field.type === 'SystemFieldRest' ? false : field.deleteableOob,
    canUpdate: field.updatebleOob,
    stage: field.stage,
    requesterCanEdit: field.requesterCanEdit,
    requesterRequired:
      useOnPortalEnabledModules.indexOf(moduleName) >= 0
        ? field.requesterRequired
        : field.required,
    fields: [],
    ...(['dropdown', 'multiSelectDropdown', 'checkbox', 'radio'].indexOf(
      type
    ) >= 0
      ? { options: field.options }
      : {}),
    ...(type === 'dependent' && field.tree
      ? {
          tree: transformTreePropertyRecursive(
            SortBy(field.tree || [], (d) => d.node.objOrder)
          ),
        }
      : {}),
    ...(['multiSelectDropdown', 'checkbox'].indexOf(type) >= 0
      ? { defaultValue: field.defaultValue || [] }
      : {}),
    // for purchase cost fied (price field)
    percentageField: field.percentageField,
    hidden: field.hidden,
    // api field properties for client
    ...(type === 'api' ? { apiFieldDetails: transformApiField(field) } : {}),
    ...(type === 'rating' ? transformRatingField(field) : {}),
    ...(['dropdown', 'multiSelectDropdown', 'dependent'].indexOf(type) >= 0
      ? {
          fieldResolutionType: field.fieldResolutionType,
          fieldReferenceType: field.fieldReferenceType,
        }
      : {}),
    assetCiTypeName:
      ((sameNameFieldsMap || {})[field.name] || {}).total > 1
        ? ((sameNameFieldsMap || {})[field.name] || {})[field.id]
        : undefined,
    pii: (field.fieldPrivacyConfigRest || {}).pii,
  }
}

export function transformFormFieldForServer(field) {
  const type =
    fieldTypeMap[
      field.isSystemField ? 'SystemFieldRest' : field.inputType || field.type
    ]
  if (!type) {
    throw new Error(`field with type ${type} is not defined`)
  }
  return {
    id: field.id,
    type,
    ...(type === 'DisplayFieldRest' ? { subType: field.type } : {}),
    attributes: field.attributes,
    orderInField: field.order,
    question: field.question,
    name: field.question ? field.question : field.title,
    maxRating: field.maxRating,
    fLabel: field.fLabel,
    tLabel: field.tLabel,
    minLength: +field.minLength,
    maxLength: +field.maxLength,
    minValue: field.minValue,
    maxValue: field.maxValue,
    required: field.required,
    markAsHidden: field.markAsHidden,
    sectionId: field.sectionId,
    requesterCanEdit: field.requesterCanEdit,
    requesterRequired: field.requesterRequired,
    event: field.scriptEvent,
    scriptId: field.scriptId,
    defaultValue:
      field.defaultValue &&
      (!field.attributes.systemManagedField ||
        field.attributes.systemManagedField === 'false')
        ? field.defaultValue
        : null,
    ...('useOnPortal' in field
      ? {
          requesterViewOnly: field.useOnPortal,
          // access level for requester
          ...(field.useOnPortal
            ? {
                groupIds: field.requesterGroups || [],
              }
            : {
                groupIds: [],
              }),
        }
      : {}),
    ...(field.isSystemField ? { systemType: field.type } : {}),
    ...(['dropdown', 'multiSelectDropdown', 'checkbox', 'radio'].indexOf(
      field.type
    ) >= 0
      ? { options: field.options }
      : {}),
    ...(field.type === 'dependent' && field.tree
      ? { tree: transformTreePropertyRecursiveForServer(field.tree || []) }
      : {}),
    ...(field.type === 'radio' ? { radioButtonField: true } : {}),
    ...('stage' in field ? { stage: field.stage } : {}),
    // for purchase cost field (price field)
    percentageField: field.percentageField,
    hidden: field.hidden,
    ...('costField' in field ? { costField: field.costField } : {}),
    // transform api field property for server
    ...(field.type === 'api'
      ? transformApiFieldForServer(field.apiFieldDetails)
      : {}),
    ...(field.type === 'rating' ? transformRatingFieldForServer(field) : {}),
    ...(['dropdown', 'multiSelectDropdown', 'dependent'].indexOf(field.type) >=
    0
      ? {
          fieldResolutionType: field.fieldResolutionType,
          fieldReferenceType: field.fieldReferenceType,
        }
      : {}),
    ...('pii' in field
      ? {
          fieldPrivacyConfigRest: {
            pii: field.pii,
          },
        }
      : {}),
  }
}

export function isListField(fieldType) {
  return (
    ['checkbox', 'radio', 'dropdown', 'multiSelectDropdown'].indexOf(
      fieldType
    ) >= 0
  )
}

export function getAdditionalFieldProps(field) {
  if (isListField(field.type)) {
    return {
      options: field.options.map((o) => ({ key: o, text: o })),
    }
  }
  if (field.type === 'dependent') {
    return {
      options: field.tree,
    }
  }
  if (field.type === 'number') {
    return {
      allowDecimal: field.attributes.allowDecimal === 'true',
    }
  }
  return {}
}

export function buildInputTypeForField(field) {
  if (isListField(field.type)) {
    if (
      (field.type === 'dropdown' || field.type === 'multiSelectDropdown') &&
      field.fieldResolutionType === 'system_field' &&
      field.fieldReferenceType
    ) {
      return field.fieldReferenceType
    } else if (field.type === 'multiSelectDropdown') {
      return 'multiSelectDropdown'
    }
    return 'dropdown'
  }
  if (field.type === 'number') {
    return 'number'
  }
  if (field.type === 'dependent') {
    if (
      field.fieldResolutionType === 'system_field' &&
      field.fieldReferenceType
    ) {
      return field.fieldReferenceType
    }
    return 'dependent'
  }
  if (field.type === 'datetime') {
    // @TODO add datetime support in future
    // if (field.attributes.allowTime) {
    //   return 'dateTime'
    // }
    return 'date'
  }
  if (field.type === 'boolean') {
    return 'boolean'
  }
  if (field.type === 'richtextarea') {
    return 'richtextarea'
  }
  if (field.type === 'upload') {
    return 'upload'
  }
  return 'text'
}

export function buildOperatorsForField(field) {
  const operatorMaps = getOperatorMaps()
  if (['multiSelectDropdown', 'checkbox'].indexOf(field.type) >= 0) {
    return [...operatorMaps.tag, ...operatorMaps.null_not_null]
  }
  if (field.type === 'dependent') {
    return operatorMaps.list
  }
  if (isListField(field.type)) {
    return [...operatorMaps.list, ...operatorMaps.blank_not_blank]
  }
  if (field.type === 'number') {
    return operatorMaps.number
  }
  if (field.type === 'datetime') {
    return operatorMaps.date
  }
  if (field.type === 'boolean') {
    return operatorMaps.boolean
  }
  return operatorMaps.text
}

export function buildValueTypeForField(field) {
  if (field.type === 'datetime') {
    return 'long'
  }
  if (field.type === 'number') {
    return 'double'
  }
  if (field.type === 'dependent') {
    return 'long'
  }
  if (field.type === 'boolean') {
    return 'boolean'
  }
  if (field.type === 'dropdown' || field.type === 'multiSelectDropdown') {
    if (field.fieldResolutionType === 'system_field') {
      return 'long'
    }
    return 'string'
  }
  return 'string'
}

export const disabledSystemFieldsBehaviour = [
  // refactor requester_email to requester
  'requester',
  'subject',
  'requesterId',
  'displayName',
  'name',
  'timezone',
  'roles',
  'dateTimeFormat',
  'dateFormat',
  'timeFormat',
]

export const bulkActionsSupportedFields = [
  'text',
  'dropdown',
  'multiSelectDropdown',
  'datetime',
  'number',
  'radio',
  'dependent',
]

export const hideRequesterSection = ['company']

export const alwaysEditableSystemFields = ['requester', 'subject']
// mandatory field which are used in form rules specially hide rule (set default value on form load)
// after apply form rule, hidden field value must be default value
// mandatorySystemFields is a list of field which are handle in formconsumer form rule
export const mandatorySystemFields = ['status', 'priority', 'impact', 'urgency']

export const piiMarkSupportedSystemCustomFields = [
  'name',
  'log_on_name',
  'user_email',
  'contact_no',
  'alt_contact_no_1',
  'alt_contact_no_2',
  'profile_pic',
  'text',
  'textarea',
  'dropdown',
  'multiSelectDropdown',
  'datetime',
  'number',
  'checkbox',
  'radio',
  'upload',
]

export function transformSystemFieldSupportedData(data) {
  return {
    displayName: data.displayName,
    hideSupported: data.hideSupported,
    paramName: data.paramName,
    type: data.type,
  }
}

export const fieldResolutionTypeOptions = () => {
  const __t = getRootTranslator()
  const __tc = getRootPluaralTranslator()
  return [
    {
      text: __t('custom'),
      key: 'user_defined',
      value: 'user_defined',
    },
    {
      text: `${__t('system')} ${__tc('field')}`,
      key: 'system_field',
      value: 'system_field',
    },
  ]
}

export const ratingTypeOptions = () => {
  const __t = getRootTranslator()
  return [
    {
      text: `${__t('star')}`,
      key: 'star',
      value: 'star',
    },
    {
      text: `${__t('emoji')}`,
      key: 'emoji',
      value: 'emoji',
    },
    {
      text: __t('custom'),
      key: 'custom',
      value: 'custom',
    },
  ]
}
