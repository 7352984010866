import { transformAttachment } from '@data/attachment'

export function transformUserConsent(consent) {
  return {
    id: consent.id,
    enabled: consent.enabled,
    enforceConsentOnUpdate: consent.enforceConsentOnUpdate,
    consentType: consent.consentType,
    consentURL: consent.consentURL,
    consentAttachment:
      Object.keys(consent.consentAttachment).length &&
      consent.consentAttachment.refFileName
        ? [transformAttachment(consent.consentAttachment)]
        : undefined,
    consentPolicyLastUpdatedOn: consent.consentPolicyLastUpdatedOn,
    canDelete: consent.deleteableOob,
    canEdit: consent.updatebleOob,
  }
}

export function transformUserConsentForServer(consent) {
  if (consent.enabled) {
    return {
      id: consent.id,
      enabled: consent.enabled,
      enforceConsentOnUpdate: consent.enforceConsentOnUpdate,
      consentType: consent.consentType,
      consentURL: consent.consentURL,
      consentAttachment: (consent.consentAttachment || [])[0],
    }
  } else {
    return {
      id: consent.id,
      enabled: consent.enabled,
    }
  }
}
