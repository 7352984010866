import { token, publicToken, isLoggedIn } from '@utils/auth'
import api from '@api'
import { errorToast } from '@motadata/ui'
import { getRootPluaralTranslator } from '@utils/get-module-translator'
const __rootTc = getRootPluaralTranslator()

export function attachmentDownloadApi(
  url,
  fileName,
  blobOnly = false,
  config = {}
) {
  return api
    .get(url, {
      timeout: 0,
      responseType: 'blob',
      notify: false,
      ...config,
    })
    .then((data) => {
      if (blobOnly) {
        return data
      }
      return import('file-saver').then((FS) => {
        return FS.saveAs(data, fileName)
      })
    })
    .catch((error) => {
      if ((error.response || {}).status === 429) {
        return errorToast(__rootTc('too_many_download_process_requests'))
      }
      if ((error.response || {}).status === 500) {
        const reader = new Response(error.response.data).text()
        return reader.then((d) => {
          const message = JSON.parse(d).userMessage
          return errorToast(message)
        })
      }
    })
}

export function buildImageUrlFromRefFileName(refFileName) {
  return `/api/public/renderImage/${refFileName}`
}

export function buildGenericImageUrlFromRefFileName(refFileName) {
  return `/api/public/download/file/${refFileName}?isViewOnly=true&mid=${
    isLoggedIn() ? token() : publicToken()
  }`
}
// export function buildImageUrlFromRefFileName(refFileName) {
//   return `/api/public/download/file/${refFileName}`
// }

export function transformAttachment(
  attachment,
  hasToken = true,
  isPortalLogin = false
) {
  return {
    uid: attachment.refFileName,
    name: attachment.realName,
    realName: attachment.realName,
    refFileName: attachment.refFileName,
    status: 'done',
    downloadApi: hasToken
      ? isPortalLogin
        ? `/cportal/download/file/${attachment.refFileName}`
        : `/download/file/${attachment.refFileName}`
      : `/public/download/file/${attachment.refFileName}`,
    previewApi: hasToken
      ? isPortalLogin
        ? `/cportal/download/file/${attachment.refFileName}?isViewOnly=true`
        : `/download/file/${attachment.refFileName}?isViewOnly=true`
      : `/public/download/file/${attachment.refFileName}?isViewOnly=true`,
    url: hasToken
      ? isPortalLogin
        ? `/api/cportal/download/file/${attachment.refFileName}?mid=`
        : `/api/download/file/${attachment.refFileName}?mid=`
      : `/api/public/download/file/${attachment.refFileName}?mid=`,
    previewUrl: hasToken
      ? isPortalLogin
        ? `/api/cportal/download/file/${attachment.refFileName}?isViewOnly=true&mid=`
        : `/api/download/file/${attachment.refFileName}?isViewOnly=true&mid=`
      : `/api/public/download/file/${attachment.refFileName}?isViewOnly=true&mid=`,
  }
}

export function transformAttachmentForServer(data) {
  return {
    refFileName: data.refFileName,
    realName: data.realName,
  }
}
